import { Environment } from './environment.interface';

export const environment: Environment = {
	// assetsUrl: 'https://storage.cloud.google.com/socino-assets/',
	// assetsUrl: 'https://storage.googleapis.com/socino-assets/',
	env: 'staging',
	sentryDSN:
		'https://a1b7690011da4cb8b3fb07be82e57f77@o4504249005047808.ingest.sentry.io/4504253262200832',
	production: false,
	baseApiUrl: 'https://staging.api.socino.com',
	apiVersion: 'v1.0',
	get apiUrl(): string {
		return `${this.baseApiUrl as string}/${this.apiVersion as string}`;
	}
};
